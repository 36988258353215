import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        baseUrl:'https://zcjc.vip/#/',
        // 默认网站关键词
        metaInfo: { }
    },
    mutations: {
        CAHNGE_META_INFO(state, metaInfo) {
            // console.log(metaInfo,"metaInfo")
            state.metaInfo = metaInfo;
        }
    },
    actions: {
    },
    modules: {
    }
})
// import Vue from "vue";
// import vuex from "vuex";
// Vue.use(vuex);
// const state = {
//     metaInfo: {
//         title:"新格尔门窗测量_为全屋测量,阳光房测量等客户提供测量设计方案",
//         keywords:"门窗测量,全屋测量,阳光房测量,全景测量,测量软件",
//         description:"测量大师适用于门窗,全屋定制行业的门店使用,以智能量尺,还原现场为出发点,从售前,售中,售后三大环节为门店提供营销获客,测量设计,入户安装,售后维保等一系列产品解决方案"
//     }
// };
// const mutations = {
//     CAHNGE_META_INFO(state, metaInfo) {
//         state.metaInfo = metaInfo;
//     }
// };
// export default new vuex.Store({
//     state,
//     mutations,
// })
