import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决重复点击路由报错
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Index',
    component: () => import('../views/Index/index.vue'),
    meta: {
     
        metaInfo:{
           title: "测量大师门窗测量_为全屋测量,阳光房测量等让测量不在出错",
          //  title: "测量大师",
        keywords: '测量大师,门窗测量,全屋测量,阳光房测量,全景测量,测量软件,新格尔软件',
        description:'测量大师适用于门窗,全屋定制行业的门店使用,以智能量尺,还原现场为出发点,从售前,售中,售后三大环节为门店提供营销获客,测量设计,入户安装,售后维保等一系列产品解决方案'
      }
      

    }
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('../views/Solution/index.vue'),
    meta: {
      title: "解决方案"
    }
  },
  {
    path: '/production',
    name: 'Production',
    component: () => import('../views/Production/index.vue'),
    meta: {
      title: "产品中心"
    }
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('../views/Production/version.vue'),
    meta: {
      title: "产品版本"
    }
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import('../views/Case/index.vue'),
    meta: {
      title: "客户案例"
    }
  },
  {
    path: '/caseDetail',
    name: 'CaseDetail',
    component: () => import('../views/Case/detail.vue'),
    meta: {
      title: "案例详情"
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News/index.vue'),
    meta: {
      title: "产品动态"
    }
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () => import('../views/News/detail.vue'),
    meta: {
      title: "新闻详情"
    }
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('../views/Partner/index.vue'),
    meta: {
      title: "合伙人招募"
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About/index.vue'),
    meta: {
      metaInfo:{
        title: "测量大师门窗测量_为全屋测量,阳光房测量等让测量不在出错",
        keywords: '测量大师,门窗测量,全屋测量,阳光房测量,全景测量,测量软件,新格尔软件',
        description:'测量大师适用于门窗,全屋定制行业的门店使用,以智能量尺,还原现场为出发点,从售前,售中,售后三大环节为门店提供营销获客,测量设计,入户安装,售后维保等一系列产品解决方案'
      }
    }
  },
  {
    path: '/try',
    name: 'Try',
    component: () => import('../views/Try/index.vue'),
    meta: {
      title: "免费试用"
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Agreement/privacy.vue'),
    meta: {
      title: "隐私政策"
    }
  },
  {
    path: '/license',
    name: 'License',
    component: () => import('../views/Agreement/license.vue'),
    meta: {
      title: "用户协议"
    }
  },

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }

})

export default router