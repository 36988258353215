import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import tools from './js/tools.js' // 全局定义接口变量
// import flexible from 'lib-flexible'
// 用了bootstrapVue再用移动端适配样式会有问题
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import md5 from 'js-md5';

import {
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import AMap from 'vue-amap'
import Toastr from 'vue2-toastr'
import 'vue2-toastr/dist/css/vue2-toastr.css'
import './css/iconfont.css'
import Meta from 'vue-meta';
// 使用vue-meta
Vue.use(Meta);


Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$Tools = tools
Vue.prototype.$md5 = md5;
// Vue.use(flexible)
Vue.use(vueSwiper);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(AMap)
Vue.use(Toastr)

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '91b032e2dae49e8b9211b6b23c742353',
  // 插件集合 （插件按需引入）
  plugin: [
    'AMap.Geolocation',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.Geocoder',
    'AMap.PlaceSearch',
  ],
})

Vue.config.productionTip = false;
// 根据路由设置标题
router.beforeEach((to, from, next) => {
  /*路由发生改变修改页面的title */
  // console.log(store,to.meta)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // console.log(to.meta.metaInfo)
  if (to.meta.metaInfo) {
    store.commit("CAHNGE_META_INFO", to.meta.metaInfo)
  }
  next();
})

new Vue({
  router,
  store,

  metaInfo() {
    // console.log(this.$store.state.metaInfo)
    return {
      title: this.$store.state.metaInfo.title,
      meta: [{
        name: "keywords",
        content: this.$store.state.metaInfo.keywords
      }, {
        name: "description",
        content: this.$store.state.metaInfo.description
      }]
    }
  },
  render: h => h(App)
}).$mount('#app')