//判断环境
let env = window.location.href.includes('test') ? "test" : (window.location.href.includes('localhost') || window.location.href.includes('192.168') || window.location.href.includes('127.0.0.1')) ? "develop" : "production";
//api地址
// let api = (env === 'production') ? 'api/thinkerx-service/public/' : '';
//dev环境的端口地址
// let port = localStorage.port !== undefined ? localStorage.port : ':5001';
const Conts = {
    env,
    domain: (env === 'develop') ? `https://me-api.thinkerx.com/api/` : (env === 'test') ? `https://me-api-test.thinkerx.com/api/` : `https://me-api.thinkerx.com/api/`,
    popupTime: 1000,
    popupTimes: [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000],
};

export default {
    Conts
}